.challenge {
    background-color: #000;
    padding: 2rem 0;
    margin-top: -5.5rem;
    position: relative;
    background-image: url('../../assets/bg2.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 80vh;
}

.challenge .heading {
    height: 8rem;
    position: absolute;
    top: -0.2%;
    left: 35%;
    transform: translate(-40%);
}

.challenge .content {
    color: var(--yellow);
    font-size: 1.3rem;
    text-align: center;
    width: 50%;
    position: absolute;
    top: 50%;
    left: 65%;
    padding: 1rem;
    transform: translate(-50%, -50%);
}

.challenge .bottom {
    position: absolute;
    bottom: -1.5rem;
    left: 0;
    object-fit: contain;
    width: 100%;
    height: 5rem;
    z-index: 1;
}

@media(width<=968px) {
    .challenge {
        margin-top: 0;
        height: 80vh;
    }

    .challenge .heading {
        height: 6rem;
    }

    .challenge .content {
        width: 80%;
        left: 50%;
    }
}

@media(width<=768px) {
    .challenge .heading {
        height: 5rem;
        left: 35%;
    }

    .challenge .content {
        width: 90%;
    }
}

@media(width<=500px){
    .challenge .heading {
        height: 4rem;
    }

    .challenge .content {
        width: 100%;
    }
}

@media(width<=350px){
    .challenge .heading {
        height: 3rem;
    }

    .challenge .content {
        font-size: 1rem;
    }
}