.topCoders {
  height: fit-content;
}

.topCodersHero {
  position: relative;
  height: 100vh;
  width: 100%;
  background: black;
  background-image: radial-gradient(rgb(255, 215, 0, 0.8) 1.5px, transparent 0),
    radial-gradient(rgb(250, 250, 250, 0.3) 1.5px, transparent 0);
  background-position: -30px 55px, 70px 105px;
  background-size: 100px 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topCodersHero .renderVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.topCodersHero .rect {
  position: absolute;
  width: 3rem;
  height: 1.3rem;
  background: var(--yellow);
}

.topCodersHero .rect:first-child {
  top: 20%;
  left: 15%;
}

.topCodersHero .rect:nth-child(2) {
  bottom: 15%;
  left: 10%;
}

.topCodersHero .rect:nth-child(3) {
  top: 30%;
  right: 15%;
}

.topCodersHero .rect:nth-child(4) {
  bottom: 25%;
  right: 0;
}

.topCodersHero .heroBar {
  width: 100%;
  position: relative;
  top: 10rem;
}

.topCodersHero .heroBar img {
  height: 5rem;
  width: 100%;
}

.topCodersHero .heroBar a {
  text-transform: uppercase;
  font-size: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.topCodersContent {
  position: relative;
  top: 5rem;
  height: fit-content;
  background-color: var(--yellow);
  display: flex;
  align-items: center;
}

.topCodersContent .topBorder {
  position: absolute;
  top: -2rem;
  width: 100%;
}

.topCodersContent .text {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.topCodersContent .text .rect2 {
  background-color: black;
  width: 3.5rem;
  height: 1.5rem;
  display: block;
}

.topCodersContent .text .textHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
}

.topCodersContent .text .textContent {
  width: 60%;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  padding-top: 1rem;
}

.topCodersContent .text h1 {
  font-size: 3rem;
}

.topCodersContent .text b {
  font-size: 4rem;
}

.topCodersContent .card {
  position: relative;
  bottom: 4rem;
  height: 40rem;
  transition: all 0.5s ease-in-out;
}

.topCodersContent .card:hover img {
  scale: 1.1;
}

.topCodersContent .text .prize {
  position: relative;
}

.topCodersContent .text .prize p:nth-child(1) {
  font-size: 2rem;
  font-weight: 100;
  letter-spacing: 1rem;
}

.topCodersContent .text .prize p:last-child {
  font-size: 6rem;
  margin-top: -1rem;
  letter-spacing: 0.2rem;
}

.topCodersContent .text .prize span {
  display: flex;
  width: fit-content;
  position: relative;
}

.topCodersContent .text .prize img {
  position: absolute;
  height: 8rem;
  top: -1rem;
  right: -10rem;
}

@media (width<=1275px) {
  .topCodersContent .card {
    height: 35rem;
    bottom: 2rem;
  }

  .topCodersContent .topBorder {
    top: -1rem;
  }

  .topCodersContent .text .prize p:last-child {
    font-size: 4rem;
  }
}

@media (width<=768px) {
  .topCodersContent {
    flex-direction: column;
    padding: 2rem 0;
  }

  .topCodersContent .text .prize p:nth-child(1) {
    font-size: 2.5rem;
  }

  .topCodersContent .text .prize p:last-child {
    font-size: 4rem;
  }

  .topCodersContent .text .prize img {
    height: 7rem;
  }

  .topCodersContent .card {
    height: 25rem;
    bottom: -1rem;
  }

  .topCodersContent .black,
  .topCodersContent .line {
    display: none;
  }
}

@media (width<=540px) {
  .topCodersHero .rect {
    width: 2rem;
    height: 0.8rem;
  }

  .topCodersHero .rect:nth-child(2) {
    bottom: 25%;
    left: 5%;
  }

  .topCodersHero .rect:nth-child(3) {
    right: 10%;
  }

  .topCodersHero .rect:nth-child(4) {
    bottom: 4%;
  }

  .topCodersHero .heroBar {
    width: max-content;
    top: 15rem;
  }

  .topCodersHero .heroBar img {
    width: 100%;
    object-fit: cover;
  }

  .topCodersHero .heroBar a {
    font-size: 1.2rem;
  }

  .topCodersContent .topBorder {
    top: -0.5rem;
  }

  /* Content Section */
  .topCodersContent .text {
    width: 100%;
    padding: 0 1rem;
  }

  .topCodersContent .text .rect2 {
    width: 2rem;
    height: 1rem;
  }

  .topCodersContent .text .textContent {
    margin-top: -1rem;
    width: 90%;
    margin-bottom: 1rem;
  }

  .topCodersContent .text h1 {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }

  .topCodersContent .text b {
    font-size: 3rem;
  }

  .topCodersContent .text .prize p:nth-child(1) {
    font-size: 1.5rem;
  }

  .topCodersContent .text .prize p:last-child {
    font-size: 3rem;
  }

  .topCodersContent .text .prize img {
    height: 5rem;
    right: -5rem;
  }

  .topCodersContent .card {
    height: 20rem;
    bottom: -1rem;
  }
}

@media (width <415px) {
  .topCodersHero .heroBar a {
    font-size: 1rem;
  }

  .topCodersContent .text .textHead .rect2:first-child {
    display: none;
  }
}

@media (width <350px) {
  .topCodersHero .heroBar a {
    font-size: 0.8rem;
  }
}

.partnersContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
}

.partnersContainer .rect2 {
  width: 2rem;
  height: 1rem;
  background-color: #fcee0a;
  margin: 0 1rem;
}

.partnersContainer .partnersHeading {
  font-size: 4rem;
  font-weight: bold;
  color: #fcee0a;
  margin: 0 1rem;
  margin-bottom: 2rem;
}

.partnersContainer div img {
  width: 10rem;
  margin: 0 1rem;
}

@media (max-width: 768px) {
  .partnersContainer .partnersHeading {
    font-size: 3rem;
  }

  .partnersContainer div img {
    width: 8rem;
  }
}

@media (max-width: 576px) {
  .partnersContainer .partnersHeading {
    font-size: 2rem;
  }

  .partnersContainer div img {
    width: 6rem;
  }
}

.instagramPosts {
    margin: 2rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
}

.instagramImage {
    width: 100%;
    height: auto;
}

@media (max-width: 1200px) {
    .instagramPosts {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 992px) {
    .instagramPosts {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 576px) {
    .instagramPosts {
        grid-template-columns: repeat(1, 1fr);
    }
}
.storyHeading{
    text-align: center;
    font-size: 3.5rem;
    font-weight: bold;
    color: #fcee0a;
    margin-top: 5rem;
    margin-bottom: 3rem;
}
