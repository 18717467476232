.topCoders {
    height: fit-content;
}

.topCodersHero {
    position: relative;
    height: 100vh;
    width: 100%;
    background: black;
    background-image: radial-gradient(rgb(255, 215, 0, .8) 1.5px, transparent 0),
        radial-gradient(rgb(250, 250, 250, .3) 1.5px, transparent 0);
    background-position: -30px 55px, 70px 105px;
    background-size: 100px 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topCodersHero .renderVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.topCodersHero .rect {
    position: absolute;
    width: 3rem;
    height: 1.3rem;
    background: var(--yellow);
}

.topCodersHero .rect:first-child {
    top: 20%;
    left: 15%;
}

.topCodersHero .rect:nth-child(2) {
    bottom: 15%;
    left: 10%;
}

.topCodersHero .rect:nth-child(3) {
    top: 30%;
    right: 15%;
}

.topCodersHero .rect:nth-child(4) {
    bottom: 25%;
    right: 0;
}

.topCodersHero .heroBar {
    width: 100%;
    position: relative;
    top: 10rem;
}

.topCodersHero .heroBar img {
    height: 5rem;
    width: 100%;
}

.topCodersHero .heroBar a {
    text-transform: uppercase;
    font-size: 1.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.topCodersContent {
    position: relative;
    height: fit-content;
    background-color: var(--yellow);
    display: flex;
    align-items: center;
}

.topCodersContent .topBorder {
    position: absolute;
    top: -2rem;
    width: 100%;
}

.topCodersContent .text {
    display: flex;
}

.topCodersContent .text .text2 {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.topCodersContent .text .text3 {
    width: 70%;
}

.topCodersContent .text .rect2 {
    background-color: black;
    width: 3.5rem;
    height: 1.5rem;
    display: block;
}

.topCodersContent .text .textHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3rem;
}

.topCodersContent .text .textContent {
    font-size: 1.3rem;
    margin-bottom: 1rem;
    padding-top: 1rem;
}

.topCodersContent .text h1 {
    font-size: 3rem;
}

.topCodersContent .text b {
    font-size: 4rem;
}

.topCodersContent .card {
    position: relative;
    bottom: 4rem;
    height: 40rem;
    transition: all .5s ease-in-out;
}

.topCodersContent .card:hover img {
    scale: 1.1;
}

.topCodersContent .text .prize {
    position: relative;
}

.topCodersContent .text .prize p:nth-child(1) {
    font-size: 2rem;
    font-weight: 100;
    letter-spacing: 1rem;
}

.topCodersContent .text .prize p:last-child {
    font-size: 5rem;
    margin-top: -1rem;
    letter-spacing: .2rem;
}

.topCodersContent .text .prize span {
    display: flex;
    width: fit-content;
    position: relative;
}

.topCodersContent .text .prize img {
    position: absolute;
    height: 8rem;
    top: -1rem;
    right: -10rem;
}

@media(width<=1280px) {
    .topCodersContent .card {
        height: 35rem;
        bottom: 2rem;
    }

    .topCodersContent .topBorder {
        top: -1rem;
    }

    .topCodersContent .text .text3 {
        width: 90%;
        padding: 0 1rem;
    }

    .topCodersContent .text .prize img {
        height: 7rem;
    }

    .topCodersContent .text .prize p:last-child {
        font-size: 4rem;
    }
}

@media(width<=768px) {
    .topCodersContent {
        flex-direction: column;
        padding: 2rem 0;
    }

    .topCodersContent .text .prize p:nth-child(1) {
        font-size: 2.5rem;
    }

    .topCodersContent .text .prize p:last-child {
        font-size: 4rem;
    }

    .topCodersContent .card {
        height: 25rem;
        bottom: -1rem;
    }

    .topCodersContent .black,
    .topCodersContent .line {
        display: none;
    }
}

@media(width<=540px) {
    .topCodersHero .rect {
        width: 2rem;
        height: .8rem;
    }

    .topCodersHero .rect:nth-child(2) {
        bottom: 25%;
        left: 5%;
    }

    .topCodersHero .rect:nth-child(3) {
        right: 10%;
    }

    .topCodersHero .rect:nth-child(4) {
        bottom: 4%;
    }

    .topCodersHero .heroBar {
        width: max-content;
        top: 15rem;
    }

    .topCodersHero .heroBar img {
        width: 100%;
        object-fit: cover;
    }

    .topCodersHero .heroBar a {
        font-size: 1.2rem;
    }

    .topCodersContent .topBorder {
        top: -.5rem;
    }

    /* Content Section */
    .topCodersContent .text .text3 {
        width: 100%;
        padding: 0 1rem;
    }

    .topCodersContent .text .rect2 {
        width: 2rem;
        height: 1rem;
    }

    .topCodersContent .text .textContent {
        margin-top: -1rem;
        width: 90%;
        margin-bottom: 1rem;
    }

    .topCodersContent .text h1 {
        font-size: 2.5rem;
        line-height: 3.5rem;
    }

    .topCodersContent .text b {
        font-size: 3rem;
    }

    .topCodersContent .text .prize p:nth-child(1) {
        font-size: 1.5rem;
    }

    .topCodersContent .text .prize p:last-child {
        font-size: 3rem;
    }

    .topCodersContent .text .prize img {
        height: 5rem;
        right: -5rem;
    }

    .topCodersContent .card {
        height: 20rem;
        bottom: -1rem;
    }
}

@media (width <415px) {
    .topCodersHero .heroBar a {
        font-size: 1rem;
    }

    .topCodersContent .text .textHead .rect2:first-child {
        display: none;
    }
}

@media (width <350px) {
    .topCodersHero .heroBar a {
        font-size: .8rem;
    }
}