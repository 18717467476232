.topCoderSelection {
    position: relative;
    height: fit-content;
    padding-top: 2rem;
    top: -2rem;
    background-color: black;
    color: #fff;
}

.topCoderSelection .selectionHead {
    display: flex;
    gap: 2rem;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 4rem;
}

.topCoderSelection .selectionHead span {
    padding-left: 1rem;
}

.topCoderSelection .selectionHead span p:first-child {
    font-size: 5rem;
    text-transform: uppercase;
}

.topCoderSelection .selectionHead span p:last-child {
    font-size: 1.7rem;
    text-transform: uppercase;
}

.topCoderSelection .slider {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: .8rem;
}

.topCoderSelection .slider::-webkit-scrollbar {
    width: 0;
    display: none;
}

.topCoderSelection .slider .d1,
.topCoderSelection .slider .d2 {
    object-fit: cover;
    width: 100%;
    height: 8rem;
}

.topCoderSelection .slider .CC {
    height: 20rem;
}

.topCoderSelection .slider .card {
    position: relative;
    height: fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .5s ease-in-out;
}

.topCoderSelection .slider .card:hover {
    scale: 1.05;
}

/* card-1 */
.topCoderSelection .slider .card .key {
    position: absolute;
    height: 13rem;
    top: -1rem;
    right: -1rem;
    animation: key 3s ease-in-out infinite;
}

/* card-2 */
.topCoderSelection .slider .card .build {
    position: absolute;
    height: 10rem;
    top: 0rem;
    right: -1rem;
    animation: key 10s ease-in-out infinite;
}

/* card-3 */
.topCoderSelection .slider .card .algo {
    position: absolute;
    height: 10.5rem;
    top: -1rem;
    right: 0rem;
    animation: key 8s ease-in-out infinite;
}

@keyframes key {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-.3rem);
    }

    100% {
        transform: translateY(0);
    }
}

.topCoderSelection .heroBar {
    width: 100%;
    display: flex;
    position: relative;
    bottom: -5rem;
    left: 0;
}

.topCoderSelection .heroBar img {
    width: 100%;
    z-index: 1;
}

@media(width<=1275px) {
    .topCoderSelection .selectionHead img {
        height: 12rem;
        padding-bottom: 2rem;
    }

    .topCoderSelection .slider {
        gap: 1.5rem;
        justify-content: center;
        padding: 0 2rem;
        overflow: scroll;
    }

    .topCoderSelection .heroBar {
        bottom: -4rem;
    }

    .topCoderSelection .heroBar img {
        height: 6rem;
        object-fit: cover;
    }

    .topCoderSelection .slider .d1,
    .topCoderSelection .slider .d2 {
        display: none;
    }
}

@media(width<=768px) {
    .topCoderSelection .heroBar {
        bottom: -3rem;
    }

    .topCoderSelection .heroBar img {
        height: 5rem;
        object-fit: cover;
    }

    /* selection section */
    .topCoderSelection .selectionHead {
        flex-direction: column-reverse;
        padding: 1rem;
    }

    .topCoderSelection .selectionHead span {
        padding-right: 0rem;
    }

    .topCoderSelection .selectionHead span p:first-child {
        font-size: 4rem;
    }

    .topCoderSelection .selectionHead span p:last-child {
        font-size: 1.3rem;
    }

    .topCoderSelection .selectionHead img {
        height: 12rem;
        padding-bottom: 2rem;
    }

    .topCoderSelection .slider {
        flex-direction: column;
        padding: 0 2rem;
    }

    .topCoderSelection .slider .CC {
        height: 30rem;
    }
}

@media(width<=540px) {
    .topCoderSelection .selectionHead {
        flex-direction: column-reverse;
        padding: 1rem;
    }

    .topCoderSelection .selectionHead span p:first-child {
        font-size: 2.5rem;
    }

    .topCoderSelection .selectionHead span p:last-child {
        font-size: 1rem;
    }

    .topCoderSelection .selectionHead img {
        height: 8rem;
    }

    .topCoderSelection .slider .CC {
        height: 25rem;
    }

    .topCoderSelection .slider .card {
        position: relative;
        height: fit-content;
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /* card-1 */
    .topCoderSelection .slider .card .key {
        height: 14rem;
    }

    /* card-2 */
    .topCoderSelection .slider .card .build {
        height: 11rem;
    }

    /* card-3 */
    .topCoderSelection .slider .card .algo {
        height: 12rem;
    }
}

@media(width<350px){
    .topCoderSelection .heroBar img {
        height: 4rem;
    }

    .topCoderSelection .slider .CC {
        height: 20rem;
    }

    /* card-1 */
    .topCoderSelection .slider .card .key {
        height: 12rem;
    }

    /* card-2 */
    .topCoderSelection .slider .card .build {
        height: 9rem;
    }

    /* card-3 */
    .topCoderSelection .slider .card .algo {
        height: 10rem;
    }
}