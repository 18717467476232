@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@100;200;300;400;500;600;700&family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap');

:root {
    --yellow: #FCEE0A;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #000;
}

::-webkit-scrollbar-thumb {
    background: var(--yellow);
    border-radius: 3px;
}

html,
body {
    background-color: #000;
    scroll-behavior: smooth;
    scroll-padding-top: 70px;
    font-family: 'Antonio', sans-serif;
}

a {
    text-decoration: none;
    color: #000;
    transition: all 0.3s ease-in-out;
}