.topcodersFooter {
    background-color: var(--yellow);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    padding-top: 2rem;
}

.topcodersFooter div {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.2rem;
}

.topcodersFooter span {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.topcodersFooter .socials {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.topcodersFooter .socials a:hover {
    scale: 1.2;
}

.topcodersFooter .footerLogos {
    margin-top: 1rem;
    padding: 3rem;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    width: 50%;
}

.topcodersFooter .footerLogos span {
    height: 2rem;
    width: 2px;
    display: block;
    background-color: white;
}

.topcodersFooter .footerLogos img {
    height: 3rem;
}

@media(width<=1275px) {
    .topcodersFooter .footerLogos {
        width: 60%;
    }

    .topcodersFooter .footerLogos img {
        height: 2rem;
    }
}

@media(width<768px) {
    .topcodersFooter .footerLogos {
        gap: 2rem;
        width: 100%;
    }
}


@media (width <350px) {
    .topcodersFooter div {
        font-size: .8rem;
    }

    .topcodersFooter .footerLogos {
        gap: 1rem;
    }

    .topcodersFooter .footerLogos img {
        height: 1.3rem;
    }
}